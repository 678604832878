import React from 'react'

import {Link, useStaticQuery, graphql} from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Container from 'contentcurator-gatsby-template/src/components/container'

import * as styles from './footer.module.css'


export const query = graphql`
query {
  strapi {
    siteSections(sort: "order:asc") {
      id
      section
      order
      show_in_menu
      page {
        slug
      }
      external_url
    }
    siteSetting {
      id
      site_name
      description: default_seo_description
      logo {
        url
        alternativeText
        caption
        imageFile{
          childImageSharp{
            gatsbyImageData(
              quality: 100
              placeholder: NONE
            )
          }
          extension
          publicURL
        }
      }
    }
  }
}
`

function ShowSection ({section}) {
  if (section.show_in_menu && (section.page || section.external_url) ){
    return (
      <li key={section.id}>
        <>
        {section.page &&
          <Link to={`/${section.page.slug}/`}>{section.section}</Link>
        }
        { !section.page && section.external_url &&
          <Link to={`${section.external_url}`}>{section.section}</Link>
        }
        </>
      </li>
    )
  }
  return ( <></>)
}
function showLogo(data, siteTitle) {

  if ( data.strapi.siteSetting.logo == null ){
    return(data.strapi.siteSetting.site_name)
  }

  const logoImage = data.strapi.siteSetting.logo && getImage(data.strapi.siteSetting.logo.imageFile)

  if (data.strapi.siteSetting.logo.imageFile.extension == 'svg'){
    return( <img className={styles.logo} src={data.strapi.siteSetting.logo.imageFile.publicURL} alt={data.strapi.siteSetting.site_name} /> )
  } else {
    return( <GatsbyImage image={logoImage} alt={siteTitle} className={styles.logo} /> )
  }
}

const Header = ({
  siteTitle, 
  onHideNav, onShowNav, showNav, 
  onHideNavSearch, onShowNavSearch, showNavSearch}) => {
  const data = useStaticQuery(query);
  const siteSections = data.strapi.siteSections
  
  return (
    <>
        <section id="call-now" className={styles.callNow}>
            <a href="tel:18017168101">
                <div class="container">
                    <div class="section-header">
                        <b>Call Now (801)&nbsp;716&minus;8101</b>          
                    </div>
                </div>
            </a>
        </section>
        <Container><footer className={styles.root}>
            <div class="container">
                <div className={styles.row}>
                    <div>

                        <a href="/">
                            {showLogo(data, siteTitle)}
                        </a>
                        
                        <div className={styles.footerCallout}>
                            <p>Front Analytics provides data science consulting.</p>
                            <p>We support executives with advanced analytics to help them accelerated innovation and digital strategy.</p></div>
                    </div>
                    
                    <div>
			                  <ul className={styles.links}>
                            {siteSections &&
                              siteSections.map(section => 
                                  <ShowSection section={section} key={section.id}></ShowSection>
                            )}
                            { process.env.CONTENTCURATOR_SITESEARCH_INDEXNAME && process.env.CONTENTCURATOR_SITESEARCH_CREDENTIALS &&
                              <li key="search">
                                <Link to='/search/'>Search</Link>
                              </li>
                            }
                            <li><Link to="/contact/">Contact</Link></li>
                            <li><Link to="/about/">About</Link></li>
                        </ul>
                    </div>
                    <div className={styles.address}>
                        <p><b>Corporate Mailing Address</b>
                            <br/>Front Analytics Inc.
                            <br/>PO-BOX 176
                            <br/>100 S 100 W
                            <br/>Pleasant Grove, UT 84062
                        </p>
                    </div>
                    
                </div>
                <div className={styles.veryBottom}>
                    <div className={styles.copyright}>
                        <div class="row">
                            <div class="col-md-6 col-sm-6 col-xs-12 display-table text-left xs-text-center">
                                <div class="display-table-cell-vertical-middle">
                                    <span className={styles.textSmall}>© 2016-2022 Front Analytics, Inc</span> <span className={styles.textSmall}><Link to="/privacy/">Privacy Policy</Link></span>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer></Container>
    </>
  )
}

export default Header